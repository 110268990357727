<template>
  <div>
    <v-combobox
        v-model="newValue"
        :items="options"
        :error="errors && errors.length > 0"
        outlined
        :class="errors && errors.length ? 'hasError' : ''"
        item-text="Name"
        item-value="Name"
        :clearable="clearable"
        :search-input.sync="searchInput"
        @update:search-input="updateSearchInput"
        @change="updateValue"
        @keydown="emitKeypress"
        :disabled="disabled || false"
        :label="required ? label + '*' : label" 
        hide-details
        no-filter
        maxlength="50"
        :loading="loading"
        
    >
      <template v-slot:append>
        <v-icon class="error-icon" v-if="errors && errors.length">mdi-alert</v-icon>            
        <v-icon v-else-if="!options.length"></v-icon>
      </template>
    </v-combobox>

    <ErrorMessages :errors="errors"></ErrorMessages>
  </div>
  
</template>

<script>
import { MortgageeOrgService } from '@/api/MortgageeOrgService'
import { sanitizeSeek } from '@/scripts/helper'

export default {
  name: "MortgageeOrgCombobox",
  components: {
  },
  data() {
    return {
      newValue: null,
      options: [],
      loading: false,
      timeout: null,
      searchInput: null,
    }
  },
  props: {
    value: Object,
    rules: String,
    label: String,
    clearable: {
      type: Boolean,
      default: true,
    },
    required: Boolean,
    errors: Array,
    disabled: Boolean,
    name: String,
    module: String
  },
  emits: [
    'change',
    'keypress'
  ],
  methods: {
      updateValue(v) {
        //alert(v);

        if (v.Name) {
          this.newValue = v;
          this.seekInput(v);  
        }
        else if (this.searchInput) {
          this.newValue = {
            ID: null,
            Name: this.searchInput
          }
        } else {
          this.newValue = null;
        }

        this.emitValue();
          
      },
      updateSearchInput(v) {
        this.seekInput(v);
      },
      emitValue() {
        this.$emit('change', this.newValue);
      },
      emitKeypress() {
        this.$emit('keypress', true);
      },
      seekInput(q) {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.seek(q);
        }, 300)
      },
      seek(q) {
        q = sanitizeSeek(q);

        //if (!this.newValue || q == this.newValue.Name) return;

        if (q && q.length > 2) {
          this.loading = true;
          
          MortgageeOrgService.seek({q: q})
          .then((data) => {
            this.options = data;
          }).catch(() => {
            
          }).finally(() => {
            this.loading = false;
          });
            
        }
      },
  },
  watch: {
      value: {
          immediate: true,
          deep: true,
          handler(newVal) {
            // if (this.newValue != newVal) {
            //   this.newValue = newVal;
            // }
            if ((!this.newValue) || (newVal && newVal.Name && this.newValue.Name != newVal.Name)) {
              this.newValue = newVal;
            }
          }
      },
  },
}
</script>